import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { RichText } from "prismic-reactjs"
import SEO from "../components/seo"
import Img from "gatsby-image/withIEPolyfill"

const Front = ({ content }) => {
  if (!content) return null
  return (
    <div>
      <SEO 
        title={'About us'} 
        description={'Volcanic Drinks has four decades of experience in craftin quality liquors.'}
        image={content[0].node.ragnar_image.url+"&w=1200"}
      />
      {content.map((content, index) => {
        return(
          <div className="contentAfterImage" key={content.node._meta.id}>
            <div className="topImageCont">
              <Img className="topImage" loading="eager" fadeIn durationFadeIn={2000} fluid={content.node.top_imageSharp.childImageSharp.fluid} alt={content.node.top_image.alt}/>
            </div>
            <div className="aboutVolcanic">
              <h1 className="aboutTitle">{RichText.asText(content.node.volcanic_header)}<span className="dot">.</span></h1>
              <p className="aboutVolcanicText">
                {content.node.volcanic_about.map((paragraph, index) => {
                  return(
                    <span key={index}>{paragraph.text}<br/></span>
                  )
                })}
              </p>
            </div>
            <div className="ragnarInfo">
              <Img objectFit="cover" objectPosition="80% 50%" loading="lazy" className="ragnarImage" fadeIn durationFadeIn={2000} fluid={content.node.ragnar_imageSharp.childImageSharp.fluid} alt={content.node.ragnar_image.alt}/>
              <h1 className="ragnarHeading">{RichText.asText(content.node.ragnar_name)}<span className="dot">,</span><br/>{RichText.asText(content.node.ragnar_title)}</h1>
              <p className="ragnarText">
                {content.node.ragnar_text.map((paragraph, index) => {
                  return(
                    <span key={index}>{paragraph.text}<br/><br/></span>
                  )
                })}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ({ data }) => {
  const contentAbout = data.prismic.allAbouts.edges
  if (!contentAbout) return null

  return (
    <Layout>
      <Front content={contentAbout} />
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      allAbouts {
        edges {
          node {
            ragnar_image
            ragnar_imageSharp{
              childImageSharp {
                    fluid(maxWidth: 1240, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
              }
            }
            _meta {
              id
            }
            ragnar_text
            ragnar_name
            ragnar_title
            top_image
            top_imageSharp{
              childImageSharp {
                    fluid(maxWidth: 3000, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
              }
            }
            volcanic_header
            volcanic_about
          }
        }
      }
    }
  }
`